(function () {
  var docEl = document.documentElement
  var resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize'
  var recalc = function () {
    var clientWidth = docEl.clientWidth
    if (!clientWidth) return
    if (clientWidth >= 1280) {
      docEl.style.fontSize = '20px'
    } else if (clientWidth < 1280) {
      docEl.style.fontSize = '10px'
    }
  }
  if (!document.addEventListener) return
  window.addEventListener(resizeEvt, recalc, false)
  document.addEventListener('DOMContentLoaded', recalc, false)
  console.log(document.documentElement)
})()
